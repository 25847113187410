import Image from 'next/image';
import React, { FC } from 'react';

import MainPsBanner from '@/public/main-ps-banner.png';

import { MAIN_PAGE_CALC_SECTION_ID } from '@/features/estimate-form';

import { TDynamicPage } from '@/entities/pages';

import { useTranslate } from '@/shared/hooks/use-translate';
import { LinkBrandButton } from '@/shared/ui/link-brand-button';

import * as Styled from './styled';

type TProps = {
    dynamicPage?: TDynamicPage;
};

export const Banner: FC<TProps> = ({ dynamicPage }) => {
    const { t } = useTranslate();

    const defaultH1 = (
        <div>
            {t('mainPageBannerTitle', { span: (chunks: string) => <span className="text-brand-green">{chunks}</span> })}
        </div>
    );

    const titleH1 = dynamicPage?.h1 ? <div dangerouslySetInnerHTML={{ __html: dynamicPage.h1 }} /> : defaultH1;
    const buttonTitle = dynamicPage?.button_text ?? t('mainPageBannerLinkButton');
    const srcImage = dynamicPage?.header_image_url ?? MainPsBanner;

    return (
        <Styled.Wrapper>
            <Styled.TitleWrapper>
                <div className="bold-title">{titleH1}</div>
                <div className="brand-button-link">
                    <LinkBrandButton
                        className="button-sizing"
                        duration={500}
                        id="calculation_button_1"
                        offset={-100}
                        smooth={true}
                        spy={true}
                        to={MAIN_PAGE_CALC_SECTION_ID}
                    >
                        <span className="brand-button-link-title">{buttonTitle}</span>
                    </LinkBrandButton>
                </div>
            </Styled.TitleWrapper>

            <Styled.ImageWrapper>
                <Image alt="Main ps banner" fill src={srcImage} />
            </Styled.ImageWrapper>
        </Styled.Wrapper>
    );
};
